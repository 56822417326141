import { useEffect, useState } from 'react';
import urls from '../constants/urls.js';

export function useApplicationUrl() {
    const [applicationUrl, setApplicationUrl] = useState(urls.MARKETING_URLS[urls.BASE_URL]);

    useEffect(() => {        
        setApplicationUrl(urls.MARKETING_URLS[window.location.origin]);
    }, []);
    
    return applicationUrl;
}