const urls = {
    BASE_URL: process.env.NODE_ENV === 'production' ? 'https://www.mikeapp.co' : 'https://uat.mikeapp.co',// 'http://localhost:61377',
    FACEBOOK_URL: 'https://www.facebook.com/mikeappofficial',
    TWITTER_URL: 'https://www.twitter.com/mikeappofficial',
    INSTAGRAM_URL: 'https://www.instagram.com/mikeappofficial',
    API_URL: process.env.NODE_ENV === 'production' ? 'https://www.mikeapp.co/api/v1.0' : 'https://uat.mikeapp.co/api/v1.0',// 'https://mikewebcore.azurewebsites.net/api/v1.0',
    MARKETING_URLS: {}
    //API_URL: 'https://www.mikeapp.co/api/v1.0'
};
//map the marketing urls to the correct application url so the signup/login redirect works
urls.MARKETING_URLS['http://localhost:8000'] = 'http://localhost:3000';
urls.MARKETING_URLS['https://uat.mikeapp.co'] = 'https://uatapp.mikeapp.co';
urls.MARKETING_URLS['https://mikewebcore.azurewebsites.net'] = 'https://mikewebapp.azurewebsites.net';
urls.MARKETING_URLS['https://www.mikeapp.co'] = 'https://app.mikeapp.co';

module.exports = urls;