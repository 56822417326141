import * as React from 'react';
import { Link } from 'gatsby';
import MailingList from './MailingList.js';
import urls from '../../constants/urls.js';

const Footer = () => { 
    return (
        <footer className="page-footer">
            <div className="container">
                <div className="page-footer__branding">
                    <img src={require('../../images/logo-mikeapp.png')} alt="Mike App"/>
                    <p className="tagline">Take charge of your music career...</p>
                </div>
            
                <MailingList /> 
                <ul className="list-inline list-topmenu">
                    <li><a href={urls.FACEBOOK_URL} target="_blank" rel="noopener noreferrer"><i className="icon-facebook" /></a></li>
                    <li><a href={urls.TWITTER_URL} target="_blank" rel="noopener noreferrer"><i className="icon-twitter" /></a></li>
                    <li><a href={urls.INSTAGRAM_URL} target="_blank" rel="noopener noreferrer"><i class="icon-instagram" /></a></li>
                </ul>
                <ul className="list-inline list-footmenu">
                    <li><Link to="/blog" className="btn-text">Blog</Link></li>
                    <li><Link to="/partners" className="btn-text">Our Partners</Link></li>
                    <li><Link to="/faq" className="btn-text">FAQ</Link></li>
                    <li><Link to="/terms" className="btn-text">Terms of Use</Link></li>
                    <li><Link to="/privacy" className="btn-text">Privacy Policy</Link></li>
                    <li><Link to="/contact" className="btn-text">Contact Us</Link></li>
                </ul>
                <p className="page-footer__copy">© {new Date().getFullYear()} LoudUp LTD. All Rights Reserved.</p>                
            </div>
        </footer>
    );
};

export default Footer;