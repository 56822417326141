import './src/styles/bootstrap.min.css';
import './src/styles/slick.css';
import './src/styles/slick-theme.css';
import './src/styles/style.scss';
import './node_modules/toastr/build/toastr.min.css';
import * as slick from './src/scripts/slick.min.js';
import React from 'react';
import Layout from './src/components/layout';
import AnalyticsService from './src/services/analyticsService.js'

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
}

if(document.referrer && document.referrer.indexOf(window.location.protocol + "//" + window.location.host) !== 0) {
    const domain = document.domain.indexOf('.') !== -1 ? document.domain.split('.').reverse().slice(0, 2).reverse().join('.') : document.domain;
    document.cookie = `referrer=${document.referrer || ''};domain=${domain}`;
}

//delay this so it doesn't affect the page load time
window.setTimeout(() => {
    AnalyticsService.initialiseGA();
    AnalyticsService.initialiseFB();
    AnalyticsService.initialiseTwitter();
    AnalyticsService.initialiseHotjar();
}, 2000);

function locationSet(location, initialLoad) {
  if(initialLoad) {
      window.setTimeout(() => logLocation(location), 3000);
  }
  else {
     logLocation(location);
  }
}

function logLocation(location) {
  AnalyticsService.logPageViewGA(location);
  AnalyticsService.logPageViewFB(location);
  AnalyticsService.logPageViewTWT(location);
}

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  locationSet(location.pathname, prevLocation === null);
  //console.log("new pathname", location.pathname)
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}
// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}