import urls from '../constants/urls.js';
import $ from 'jquery';

class MailingListService {
    static subscribe (email, success, failure)
    {       
        //change this to a fetch 
	    $.post(urls.API_URL + '/MailingList?email=' + email,  (data, status, xhr) =>{
            if(success){
                success(data, status);
            }
        })
        .fail(function(error) {
            if(failure) {
                failure(error);
            }
        });
    }
}
export default MailingListService;