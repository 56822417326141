import React, { useState } from 'react';
import MailingListService from '../../services/mailingListService.js';
import AnalyticsService from '../../services/analyticsService.js';

function MailingList () {
    const [email, setEmail] = useState('');
    const [color, setColor] = useState('#555');
    const defaultColor = '#555';

    const subscribe = (event) => {
        event.preventDefault();
        MailingListService.subscribe(email, (data, status) => {
            setEmail('Thanks!');
            setColor('green');
            AnalyticsService.logEventGA('mailinglist', 'join');
            AnalyticsService.logEventFB('MikeMailingList');
            AnalyticsService.logEventTWT('/MailingList');
        },
        (error) => {
            setColor('red');
        });
    }

    const emailFocused = () => {
        if(email === 'Thanks!') {
            setEmail('');
        }
        setColor(defaultColor);
    }

    return (
        <div id="mailingList" className="newsletter">
            <form className="form">
                <p className="cblock__head">Subscribe to the Mike Mailing List</p>
                <p>And receive news and promotions directly to your inbox</p>
                <div className="form-group form-group-lg">
                    <div className="form-prefix">
                        <i className="icon-email picto" />
                        <input id="email" className="form-control" type="text" name=" " value={email} onChange={(e) => setEmail(e.target.value)} onFocus={emailFocused} placeholder="Enter your email" style={{color: color}} />
                        <button type="submit" className="btn btn-submitnewsletter" onClick={subscribe}><i className="icon-arrow-alt2" /></button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default MailingList;