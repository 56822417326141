 /* eslint-disable */
 class AnalyticsService {

    static initialiseGA() {
        //initialise google analytics
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', 'UA-89912673-1', 'auto');
    }

    static initialiseFB() {
        //initialise FB analytics
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '756859017696922');
    }

    static initialiseTwitter() {
        //initialise Twitter analytics
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        // Insert Twitter Pixel ID and Standard Event data below
        twq('init','nzpq5');
    }

    static initialiseHotjar() {   
        //initialise Hotjar
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1954271,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

    static logPageViewGA(url) {
        if(window.ga) {
            window.ga('send', 'pageview', url);
        }
    }

    static logPageViewFB(url) {
        if (window.fbq) {
            window.fbq('track', 'PageView');
        }
    }

    static logPageViewTWT(url) {
        if (window.twq) {
            window.twq('track', 'PageView');
        }
    }

    static logEventGA(category, action, label) {
        if (window.ga) {
            window.ga('send', 'event', category, action, label);
        }
    }

    static logEventFB(eventName) {
        if (window.fbq) {
            window.fbq('trackCustom', eventName);
        }
    }

    static logEventTWT(eventName) {
        debugger;
        if (window.twq) {
            window.twq('track', eventName);
        }
    }
}
export default AnalyticsService;