import React, { useEffect } from "react"
import Header from './common/Header';
import Footer from './common/Footer';
import $ from 'jquery';

//useStaticQuery enables us to query outside a page component
//import { useStaticQuery, Link, graphql } from "gatsby"

export default function Layout({ children, location }) {
  useEffect(() => {
    $('body').addClass('loaded');
  
    //-- Header sizing on scroll
    $(window).on('scroll', function() {
      changeHeader();   
    });
    
    //-- Change header on scroll
    changeHeader();  
  }, [])

  const changeHeader = () => {
    if ($(window).scrollTop() > 80) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  }

  return (
    <div>
      <div className="overlay" />
      <Header location={location} />        
      {children} 
      <Footer /> 
    </div>
  )
}