import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import urls from '../../constants/urls.js';
import { useApplicationUrl } from '../../utils/customHooks.js';

function Header({ location }){
    const applicationUrl = useApplicationUrl();

    useEffect(() => {
        const json = require('../../scripts/LogoAnimation.json');
        const bodymovin = require('bodymovin');     
                   
        bodymovin.loadAnimation({
            container: document.getElementById('logo-anim'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: json
        });

        bodymovin.loadAnimation({
            container: document.getElementById('type-anim'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: json
        });
    }, [])

    return (
        <header className="page-header">
            <nav>
                <div className="container">
                    <div className="navbar-top">
                        <div className="navbar-top__logo">
                            <Link to="/" className="btn-backhome">
                                <div className="logo-anim" id="logo-anim" />
                                <div className="type-anim" id="type-anim" />
                            </Link>
                        </div>
                        <div className="navbar-top__nav">
                            <ul className="list-inline list-topmenu">                        
                                <li><a className="soc" href={urls.FACEBOOK_URL} target="_blank" rel="noopener noreferrer"><i className="icon-facebook" /></a></li>
                                <li><a className="soc" href={urls.TWITTER_URL} target="_blank" rel="noopener noreferrer"><i className="icon-twitter" /></a></li>
                                <li><a className="soc" href={urls.INSTAGRAM_URL} target="_blank" rel="noopener noreferrer"><i class="icon-instagram" /></a></li>
                                <li><Link to="/blog" className="btn-text btn-blog">Blog</Link></li>
                                <li><a href={`${applicationUrl}/login`} className="btn btn-outline btn-login">Login</a></li>
                                <li><a href={`${applicationUrl}/signup`} className="btn btn-primary btn-signup">Start Trial</a></li>
                            </ul>
                        </div>                                
                    </div> 
                </div>
            </nav>
        </header>
    );
}

export default Header;